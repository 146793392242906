import '../styles/global.scss';
import '../styles/pages/trial-career.scss';
import {BigLogoImage, serv2, serv3, serv4, serv5} from "../images";

import {HeadFC, Link} from "gatsby";

import React, { useEffect, useState } from 'react'
import TimerCTA from '../components/TimerCTA';
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import QuizFooter from '../components/QuizFooter';
import useAnalytics from "../hooks/useAnalytics";

const AppstoreTrial = () => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);
    const [eventStatus, setEventStatus] = useState(false);

    useEffect( () => {
        if(!eventStatus){
            // fbq("track", "AddToCart");
            console.log('fb track AddToCart');
            setEventStatus(true);
        }
    }, [eventStatus]);


    return (
        <>
            <div className="MobileWrap">
                <div className="Trial TrialCareer">
                    <div className="containerSpec">
                        <div className="TrialBannerBox">
                            <a href="" className="TrialBannerLogo mb0">
                                <img src={BigLogoImage} alt="" />
                            </a>
                        </div>
                        <h2 className="TrialCareerTitle">Skyrocket your productivity and career growth</h2>
                        <p className="TrialCareerSubtitle">You don’t need a lot of apps to support your own productivity. Try AEER</p>
                        <div className="TrialCareerItems">
                            <div className="TrialCareerItem">
                                <div className="TrialCareerIcon">
                                    <img src={serv2} alt="" />
                                </div>
                                <p className="TrialCareerText">Develop new soft skills</p>
                            </div>
                            <div className="TrialCareerItem">
                                <div className="TrialCareerIcon">
                                    <img src={serv3} alt="" />
                                </div>
                                <p className="TrialCareerText">Manage your finances</p>
                            </div>
                            <div className="TrialCareerItem">
                                <div className="TrialCareerIcon">
                                    <img src={serv4} alt="" />
                                </div>
                                <p className="TrialCareerText">Set tasks and track your goals by one click</p>
                            </div>
                            <div className="TrialCareerItem">
                                <div className="TrialCareerIcon">
                                    <img src={serv5} alt="" />
                                </div>
                                <p className="TrialCareerText">Note important decisions and actions into private business diary</p>
                            </div>
                        </div>

                    </div>

                    <TimerCTA timerVal="2:00" link="https://apps.apple.com/app/aeer/id1630377140" />
                    <QuizFooter />
                    <div className="AppstoreSpace"></div>
                </div>
            </div>
        </>
    )
}

export default AppstoreTrial;

export const Head: HeadFC = () => (
    <>
        <title>Upgrade yourself - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
